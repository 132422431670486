.header {
	background: $accent;
	display: flex;
	align-items: center;
	justify-content: center;
	background-size: cover;
	position: relative;
	min-height: 100vh;
	padding: 65px 0;
	color: $white;
	z-index: 1;
	&__content {
		flex: 1 1 auto;
		h1 {
			margin-bottom: 15px; }
		h2 {
			font-size: 18px;
			font-weight: 400;
			margin-bottom: 60px;
			.scroll_to {
				color: inherit;
				position: relative;
				font-weight: 600;
				display: inline-block;
				&:before {
					content: '';
					position: absolute;
					bottom: -3px;
					left: 50%;
					width: 105%;
					height: 2px;
					background: #fff;
					transform: translateX(-50%); }
				&:hover {
					text-decoration: none;
					&:before {
						animation: 1s ease-out infinite w-pulse; } } } }


		&__title {
			margin-bottom: 1em;
			h1 {
				margin-bottom: 0; }
			h2 {
				line-height: 1.4;
				display: block;
				font-size: 18px;
				font-weight: 400;
				margin-top: 20px; } } }
	.bg-1, .bg-2 {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: $accent url("/assets/reborn/img/background.jpg") 50% 100% no-repeat;
		background-size: cover;
		z-index: -2;
		// +r(425)
		// 	background-image: url("/assets/reborn/img/background-m.jpg") 50% 50% no-repeat
		// 	background-size: cover
		.bot {
			position: absolute;
			left: calc(50% - 125px);
			bottom: 0;
			background: url("/assets/reborn/img/bot.png") 50% 100% no-repeat;
			width: 250px;
			height: 140px;
			&:before,&:after {
				content: '';
				position: absolute;
				width: 50px;
				height: 6px;
				top: 13px;
				left: calc(50% - 25px);
				background: $light-accent;
				border-radius: 5px;
				animation: 2s linear infinite spin; }
			&:after {
				width: 6px;
				height: 50px;
				top: -9px;
				left: calc(50% - 3px); } } }

	.bg-2 {
		background-image: url("/assets/reborn/img/lern-bg.jpg");
		background-position: 100% 100%;
		background-size: auto;
		@include r(425) {
			background-image: url("/assets/reborn/img/lern-bg.jpg"); } }
	#drawOnMe {
		position: absolute;
		z-index: -1;
		width: 100%;
		height: 100%;
		opacity: .2; } }

.logo {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 50px; }



.top-line {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: space-between;
	padding: 20px 30px;
	@include r(425) {
		padding: 10px 15px; }
	a {
		color: $white;
		&:hover {
			text-decoration: none; } }
	&__left {
		display: flex;
		.crumbs {
			margin-left: 15px; } }
	&__menu,&__right,.crumbs {
		padding-top: 25px; }
	&__menu {
		ul {
			display: flex;
			margin-bottom: 0;
			text-transform: uppercase; }
		a {
			margin: 10px 15px;
			position: relative;
			z-index: 1;
			&:before {
				content: '';
				position: absolute;
				left: 50%;
				bottom: 2px;
				transform: translateX(-50%);
				width: 0;
				height: 8px;
				background: $light-accent;
				border-radius: 5px;
				opacity: 0;
				transition: width .2s cubic-bezier(.5,-0.5,.5,1.5);
				z-index: -1; }
			&:hover:before {
				width: 120%;
				opacity: 1; } }

		@include r(980) {
			display: none; } }

	&__right {} }

.tl {
	&__phone .fa {
		margin-left: 5px; } }

// Преимущества

.benefits {
	padding-bottom: 60px; }

.benefit {
	&-wrap {
		padding: 0 10px;
		margin-bottom: 30px;
		transition: all .5s ease;
		&:hover {
			transform: translateY(-5px); } }
	&-icon {
		margin-bottom: 20px;
		color: $accent;
		.icon {
			width: 50px;
			height: 50px; } }
	&-title {
		font-weight: 600;
		line-height: 1.2;
		margin-bottom: 10px; }
	&-descript {
		font-weight: 400;
		font-size: 14px;
		opacity: .75; } }


// cources

.cources {
	background: $accent url("/assets/reborn/img/lern-bg.jpg") 100% 100% no-repeat;
	color: $white;
	@include r(425) {
		text-align: center; }
	&-title {
		line-height: 1;
		margin-bottom: 40px;
		@include css-lock(32, 50, 600, 1000); } }



// ourprogram

.ourprogram {
	position: relative;
	overflow: hidden;
	.hand {
		position: absolute;
		bottom: 0;
		left: 50%;
		max-height: 100%;
		@include r(768) {
			display: none; } }
	@include r(425) {
		text-align: center; }
	&-title {
		line-height: 1;
		margin-bottom: 40px;
		@include css-lock(32, 50, 600, 1000); }
	&-test {
		b {
			@include css-lock(18, 30, 600, 1000); } }
	&-small {
		color: #999;
		margin-bottom: 40px; } }


// Тарифы

.tariff {
	background: $accent url("/assets/reborn/img/bg.jpg") center;
	background-size: 100%;

	&-wrap {
		position: relative;
		background: #fff;
		padding-top: 20px;
		border-radius: 5px;
		z-index: 1;
		transition: all .25s;
		max-width: 425px;
		margin: 0 auto 20px; }

	&-aktsiya {
		position: absolute;
		top: 0;
		right: 0;
		text-align: center;
		padding: 0 10px;
		border-radius: 15px;
		color: $white;
		font-weight: 600;
		background: red;
		transform: translate(15px, -30%) rotate(15deg);
		@include r(480) {
			right: 10px; } }

	&-title {
		padding: 0 10px;
		h3 {
			color: $accent;
			font-size: 24px;
 }			// margin-bottom: 20px
		span {
			color: $gray;
			font-size: 14px;
			display: block; } }
	&-price {
		min-height: 65px;
		line-height: 1.2;
		margin-bottom: 10px;
		sub {
			margin-left: 3px;
			bottom: 0; }
		&--current {
			font-size: 30px;
			font-weight: 600; }
		&--current-center {
			font-size: 40px;
			font-weight: 600; }
		&--old {
			font-size: 14px;
			color: $gray;
			span {
				position: relative;
				&:before {
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					width: 110%;
					height: 2px;
					background: #000;
					transform: translate(-50%) rotate(5deg);
					opacity: .6; } } } }

	&-list {
		padding: 0 10px;
		font-size: 15px;
		li {
			line-height: 1.2;
			margin-bottom: 5px;
			position: relative;
			padding-left: 18px;
			margin-bottom: .5em; }
		.fa {
			color: $accent;
			// position: absolute
			// left: 0
 }			// top: 1px
		small {
			opacity: .5; } }

	&-delay {
		padding: 0 10px;
		margin-bottom: 15px;
		// font-weight: 600
		font-size: 15px;
		color: $gray;
		b {
			color: green;
			display: inline-block; } }

	&-button-wrap .button {
		margin-bottom: 10px; } }

// Works
.works {
 }	// overflow-x: hidden
.owl-works-item {
	border-radius: 10px;
	border: 2px solid #e0e0e0;
	transition: all .25s ease-out;
	&:hover {
		border-color: $accent; }

	img {
		border-radius: 10px; } }

.contact-form {
	.form {
		justify-content: center; } }

// Отзывы

.video-reviews {
	background: $accent url("/assets/reborn/img/bg.jpg") center;
	background-size: 100%;
	&-wrap {
		position: relative;
		min-height: 120px;
		margin-bottom: 20px; }
	&-preview {
		background: rgba(#fff,.25);
		position: relative;
		display: block;
		color: #fff;
		width: 150px;
		height: 150px;
		border-radius: 100px;
		overflow: hidden;
		box-shadow: 0 7px 15px -10px rgba(#000,.5);
		margin: 0 auto 15px;
		.icon {
			position: absolute;
			font-size: 40px;
			z-index: 5;
			top: calc(50% - 20px);
			left: calc(50% - 20px);
			transition: all .25s ease-out; }
		img {
			display: block;
			height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: center; }
		&:hover {
			color: #fff;
			transform: translateY(-2px);
			box-shadow: 0 13px 15px -10px rgba(#000,.3);
			.icon {
				transform: scale(.9);
				opacity: 1; } } }
	&-booble {
		position: relative;
		background: #fff;
		border-radius: 10px;
		padding: 10px 15px;
		&:before, &:after {
			content: '';
			position: absolute;
			background: #fff;
			border-radius: 15px;
			animation: 1s ease-out infinite scale; }
		&:before {
			left: calc(50% + 50px);
			top: -20px;
			width: 16px;
			height: 16px;
			animation-delay: .2s; }
		&:after {
			left: calc(50% + 45px);
			top: -31px;
			width: 10px;
			height: 10px; } }

	&-title {
		color: $accent;
		font-weight: 600; }

	&-city {
		opacity: .75;
		font-size: 12px;
		margin-bottom: 10px; }

	&-description {
		margin-bottom: 10px; }

	&-inst {
		font-size: 14px;
		font-weight: 600; } }

.owl-reviews {
	.owl-dots {
		padding-top: 20px; }
	.owl-dot {
		background: #fff; }
	.owl {
		&-nav {
			top: auto;
			bottom: 0;
			left: calc(50% - 150px);
			width: 300px;
			height: 0; }
		&-prev, &-next {
			background-color: #fff;
			color: $accent;
			left: 0;
			right: 0;
			transform: translateY(-100%); } } }

.footer {
	padding: 60px 0 30px;
	.logo {
		color: $accent;
		margin-bottom: 20px; }
	&-contacts {
		margin-bottom: 20px;
		a {
			color: $gray;
			&:hover {
				color: $gray;
				text-decoration: none; } } }
	&-socials a {
		margin-right: 10px;
		font-size: 30px;
		@include r(480) {
			font-size: 48px;
			padding: 10px;
			margin: 5px; } }
	&-payments {
		@include r(991) {
			margin-top: 30px; } }
	&-button {
		margin-bottom: 20px; }
	&-copy {
		padding-top: 30px;
		font-size: 13px;
		opacity: .5; } }

.start {
	.start-title {
		@include css-lock(24, 36, 600, 1000);
		margin-bottom: 1em; }
	&-list {
		margin-bottom: 40px;
		max-width: 425px;
		list-style-type: decimal; }
	&-socials {
		span {
			display: inline-block;
			text-align: center;
			font-size: 14px; }
		a {
			margin-bottom: 5px; }
		span + span {
			margin-left: 10px; } } }

.payment {
	&-service-price {
		border-top: 1px solid rgba(#fff,.25);
		margin-top: 10px;
		padding-top: 10px; }
	&-service-sum {
		font-size: 28px;
		font-weight: 600;
		line-height: 1.2; }
	&-service {
		.link {
			padding: 5px;
			display: inline-block;
			opacity: .5; } } }

.card {
	position: relative;
	text-align: center;
	max-width: 100%;
	width: 400px;
	height: 250px;
	background: $accent;
	border-radius: 10px;
	color: #fff;
	letter-spacing: 2px;
	font-size: 28px;
	margin-bottom: 20px;
	@include r(458) {
		height: 200px;
		font-size: 24px; }
	@include r(375) {
		height: 175px;
		font-size: 20px; }
	@include r(320) {
		height: 150px;
		font-size: 18px; }
	span {
		display: block;
		white-space: nowrap;
		position: absolute;
		bottom: 15px;
		left: 50%;
		transform: translateX(-50%); } }

.pay_sum {
	padding-top: 20px;
	line-height: 1.2;
	span {
		font-size: 30px; } }

.pay_options {
	@include r(320) {
		.button {
			width: 100%; } } }
