// Переменные
 :root {
	--white: #fff;
	--accent: #7590E6;
	--dark-accent: #4F76D8;
	--light-accent: #9FB6FF;
	--gray: #666; }

$accent: var(--accent);
$dark-accent: var(--dark-accent);
$light-accent: var(--light-accent);
$white: var(--white);
$gray: var(--gray);

$default-font: "MuseoSans", sans-serif;

// Подлключаем шрифты
// +font-face(MuseoSans, light, /fonts/MuseoSansLight, 300, normal, false )
@include font-face(MuseoSans, regular, /fonts/MuseoSansRegular, 400, normal, false );
// +font-face(MuseoSans, medium, /fonts/MuseoSansMedium, 500, normal, false )
@include font-face(MuseoSans, bold, /fonts/MuseoSansBold, 600, normal, false );
// +font-face(MuseoSans, black, /fonts/MuseoSansBlack, 900, normal, false )

html,body {
 }	// overflow-x: hidden

body {
	background: #FFF;
	font-size: 16px;
	line-height: 1.6;
	font-family: $default-font;
	@include r(425) {
		font-size: 14px;
		line-height: 1.4; } }

.loader {
	position: fixed;
	background: $accent;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999999;
	&__text {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 40px;
		height: 40px;
		transform: translate(-50%, -50%);
		border: 2px solid #fff;
		border-radius: 20px;
		animation: 1s ease-out infinite btn-loader-pulse; } }

.splitter {
	span {
		display: inline-block;
		min-width: 0.3em; } }

// TYPE STYLE

h1, h2, h3, h4, h5, h6 {
	font-weight: 600;
	line-height: 1.2; }

h1,.h1 {
	@include css-lock(32, 50, 600, 1000); }

h2,.h2 {
	@include css-lock(24, 36, 600, 1000);
	font-weight: 600; }

h3,.h3 {
	font-size: 18px;
	font-weight: 600; }

h4,.h4 {
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 16px; }

hr {
	border: none;
	border-bottom: 1px solid rgba(#fff,.25); }

.img-responsive {
	display: inline-block;
	max-width: 100%;
	height: auto;
	user-select: none;
	cursor: default;
	pointer-events: none; }

.title {
	margin-bottom: .2em;
	&-big {
		margin-bottom: 50px; } }

.sub-title {
	margin-bottom: 50px; }

.white {
	color: $white; }

a {
	transition: all .15s ease-out; }

.link {
	&-white {
		color: #fff;
		&:hover {
			color: #fff;
			opacity: .75;
			text-decoration: none; } } }

.left {
	text-align: left;
	@include rmin(576) {
		&-sm {
			text-align: left; } }
	@include rmin(768) {
		&-md {
			text-align: left; } }
	@include rmin(992) {
		&-lg {
			text-align: left; } } }

.center {
	text-align: center;
	@include rmin(576) {
		&-sm {
			text-align: center; } }
	@include rmin(768) {
		&-md {
			text-align: center; } }
	@include rmin(992) {
		&-lg {
			text-align: center; } } }

.right {
	text-align: right;
	@include rmin(576) {
		&-sm {
			text-align: right; } }
	@include rmin(768) {
		&-md {
			text-align: right; } }
	@include rmin(992) {
		&-lg {
			text-align: right; } } }

.big {
	font-size: 1.2em; }

.small {
	font-size: 0.875em;
	line-height: 1.4; }

.icon {
	fill: currentColor;
	&-telegram {
		color: #61A8DE; }
	&-viber {
		color: #7D3DAF; }
	&-messenger {
		color: #2196F3; } }

.content {
	padding-top: 100px;
	padding-bottom: 100px;
	@include r(425) {
		padding-top: 50px;
		padding-bottom: 50px; } }

.flex {
	display: flex;
	align-items: center; }

.hidden {
	display: none;
	@include r(576) {
		&-sm {
			display: none; } }
	@include rmin(576) {
		&-sm-up {
			display: none; } }
	@include r(768) {
		&-md {
			display: none; } }
	@include rmin(768) {
		&-md-up {
			display: none; } }
	@include r(992) {
		&-lg {
			display: none; } }
	@include rmin(992) {
		&-lg-up {
			display: none; } } }



// BUtton

.button {
	position: relative;
	display: inline-block;
	color: #fff;
	background: $accent;
	padding: 14px 25px;
	max-width: 100%;
	line-height: 1.2;
	border-radius: 100px;
	border: none;
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
	text-align: center;
	outline: none;
	cursor: pointer;
	box-shadow: 0 7px 15px -10px rgba(#000,.5);

	span {
		display: block;
		text-transform: none;
		font-size: .75em;
		font-weight: 400;
		opacity: .8; }

	&-circle {
		font-size: 40px;
		line-height: .75em;
		padding: .5em;
		border-radius: 50%;
		text-align: center;
		.icon {
			vertical-align: middle; } }

	&-loader {
		display: none;
		position: absolute;
		top: 50%;
		left: 50%;
		width: 40px;
		height: 40px;
		transform: translate(-50%, -50%);
		border: 2px solid #fff;
		border-radius: 20px;
		animation: 1s ease-out infinite btn-loader-pulse; }

	&-content {
		transition: opacity .15s ease-out; }

	&[disabled] {
		background-color: #f0f0f0;
		opacity: .5;

		.button-content {
			opacity: 0; }
		.button-loader {
			display: block; }
		&:hover {
			background-color: #f0f0f0;
			opacity: .5;
			transform: translate(0, 0); } }

	&-green {
		background: #53d33e; }


	&.pulse:before {
		border-radius: 100px; }

	&:hover {
		color: #fff;
		text-decoration: none;
		transform: translateY(-2px);
		box-shadow: 0 13px 15px -10px rgba(#000,.3); }

	&:active {
		transform: translateY(1px); }

	&:focus {
		outline: none; }

	&-white, &-white:hover {
		background: #fff;
		color: $accent; } }


// ФОРМЫ // Forms
input[type=range] {
	width: 100%;
	outline: 0;
	-webkit-appearance: none;
	cursor: pointer;
	vertical-align: middle;
	background-color: #ddd;
	border-radius: 3px;
	height: 3px;
	margin: 20px 0 30px; }

label[range] {
	display: block;
	position: relative;
	.range-text {
		padding-right: 20px; }
	.range-value {
		position: absolute;
		top: 0;
		right: 0;
		background: $accent;
		color: #fff;
		min-width: 1.8em;
		height: 1.8em;
		text-align: center;
		line-height: 1.8em;
		border-radius: 2em; } }

input[type=range] {
	width: 100%;
	outline: 0;
	-webkit-appearance: none;
	cursor: pointer;
	vertical-align: middle;
	background-color: #ddd;
	border-radius: 3px;
	height: 3px;
	margin: 20px 0 30px;
	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		background: #fff;
		width: 28px;
		height: 28px;
		border-radius: 50%;
		border: 1px solid #ddd;
		box-shadow: 0 4px 4px rgba(0,0,0,.1);
		transition: all .25s;
		&::-webkit-slider-thumb:active {
			width: 32px;
			height: 32px; } } }

label[radio] {
	cursor: pointer;
	padding: 5px 0;
	margin-bottom: 5px;
	input {
		display: none; }
	span {
		position: relative;
		display: inline-block;
		padding-left: 1.5em;
		&:before, &:after {
			content: '';
			position: absolute;
			left: 0;
			top: calc(50% - .5em);
			display: block;
			width: 1em;
			height: 1em;
			background: #fff;
			border-radius: 1em; }
		&:before {
			filter: drop-shadow(0 0 2px rgba(#000,.25)); }
		&:after {
			background: $accent;
			width: calc(1em - 4px);
			height: calc(1em - 4px);
			left: 2px;
			top: calc(50% - .5em + 2px);
			opacity: 0;
			transition: all .5s ease-out; } }
	input:checked + span {
		&:after {
			opacity: 1;
			transform: rotateY(180deg); } } }



label[input] {
	display: block;
	position: relative;
	background: #fff;
	line-height: 1.2;
	padding: 14px 30px;
	border-radius: 100px;
	cursor: text;
	border: 1px solid #e0e0e0;
	box-shadow: inset 0 3px 10px -5px rgba(#000,.1);
	input {
		width: 100%; }

	&.empty-field {
		border-color: red; }

	&.input-icon {
		padding-left: 50px;
		> .fa {}
		color: $accent {
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			text-align: center;
			width: 50px;
			font-size: 1.2em; } }

	&:hover {
		border-color: #d0d0d0; } }

.form-wrap {
	// padding: 0 10px
	label[input],button {
		width: 100%;
		margin-bottom: 10px; } }

// Input Button Icons
// Иконки в кнопках и полях

.button-icon,.input-icon {
	padding-left: 50px;
	> .fa {
		color: $accent;
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		text-align: center;
		width: 50px;
		font-size: 1.2em; } }

.button-icon > .fa {
	color: currentColor; }

// Модальные окна

.fancybox-slide {
	padding: 15px; }

.fancybox-button,.fancybox-close-small {
	transition: all .25s ease-out;
	&:hover {
		transform: rotate(-90deg); }
	svg {
		opacity: 1; } }

.fancybox-button {
	color: #fff;
	background: transparent;
	&:hover {
		color: #fff; } }

.fancybox-close-small {
	top: 5px;
	color: #ccc;
	&:hover {
		color: $accent; } }

.fancybox-slide--html .fancybox-content {
	overflow-x: hidden; }

.fancybox-slide--video .fancybox-content {
	border-radius: 10px;
	overflow: hidden; }

.modal {
	display: none;
	border-radius: 10px;
	width: 425px;
	max-width: 100%;
	&.fancybox-content {
		padding: 0; }
	&-form {}
	&-header {
		display: flex;
		flex-flow: row wrap;
		min-height: 55px;
		line-height: 1.2;
		padding: 5px 40px 0 20px;
		margin-bottom: 20px;
		border-bottom: 1px solid #f0f0f0;
		p {
			width: 100%; }
		.h2 {
			margin-bottom: 5px; } }
	&-body, &-footer {
		padding: 0 20px; }
	&-footer {
		opacity: .7; }
	.check-phone {
		display: inline-block;
		padding: 2px 5px;
		color: $accent;
		line-height: 1.2;
		font-size: 18px;
		font-weight: 600;
		margin: 10px 0 25px;
		&.pulse:before {
			border-radius: 50px; } } }

// accordion

.accordion {
	&_link {
		color: inherit;
		cursor: pointer;
		display: block;
		position: relative;
		padding: 10px;
		border-bottom: 1px solid rgba(#fff, .15);
		&:hover {
			color: inherit;
			text-decoration: none; }
		.fa {
			position: relative;
			margin-right: 10px;
			&:after {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				width: 25px;
				height: 25px;
				background-color: #fff;
				border-radius: 50%;
				z-index: -1;
				transform: translate(-50%, -50%) scale(0);
				transition: all .5s cubic-bezier(.5,-.5,.5,2); } }
		&.active .fa {
			color: $accent;
			transform: rotate(90deg);
			&:after {
				transform: translate(-50%, -50%) scale(1); } } }
	&_content {
		display: none;
		padding: 10px;
		border-bottom: 1px solid rgba(#fff, .15); } }

// Карусели

.owl {
	&-nav {
		position: absolute;
		display: flex;
		justify-content: space-between;
		top: calc(50% - 46px);
		width: 100%; }
	&-prev,&-next,&-dot {
		&:focus {
			outline: none; } }
	&-prev, &-next {
		position: relative;
		top: 50%;
		background: $accent;
		color: $white;
		border-radius: 50%;
		height: 46px;
		width: 46px;
		font-size: 20px;
		box-shadow: 0 7px 15px -10px rgba(#000,.5);
		&:hover {
			transform: translateY(-2px);
			box-shadow: 0 13px 15px -10px rgba(#000,.3); } }
	&-prev {
		padding-right: 2px;
		left: 0;
		transform: translateX(-5px); }
	&-next {
		padding-left: 2px;
		transform: translateX(5px); }
	&-dots {
		text-align: center;
		padding: 10px; }
	&-dot {
		width: 10px;
		height: 10px;
		background: $accent;
		margin: 3px;
		border-radius: 15px;
		transition: all .5s cubic-bezier(.5,-0.5,.5,2);
		&.active {
			width: 20px; } }
	&-carousel:hover .owl-nav {
		opacity: 1;
		transition-duration: .25s; } }


// Эффекты

.pulse {
	position: relative;
	z-index: 1;
	&:before {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		border: 1px solid $accent;
		border-radius: 5px;
		z-index: -1;
		filter: drop-shadow(0 0 3px $accent);
		animation: 1.3s ease-out infinite pulse;
		transform: translate(-50%, -50%); }
	&.pulse-white:before {
		border-width: 1px;
		border-color: #fff;
		filter: drop-shadow(0 0 3px #fff); } }


@keyframes spin {
	0% {
		transform: rotate(0); }
	100% {
		transform: rotate(360deg); } }

@keyframes scale {
	0%, 100% {
		transform: scale(.9); }
	50% {
		transform: scale(1.05); } }

@keyframes pulse {
	0% {
		width: 100%;
		height: 100%;
		opacity: 0; }
	70% {
		opacity: 1; }
	100% {
		width: calc(100% + 25px);
		height: calc(100% + 25px);
		opacity: 0; } }

@keyframes w-pulse {
	0%, 100% {
		width: 105%; }
	50% {
		width: 80%; } }

@keyframes btn-loader-pulse {
	0% {
		width: 0;
		height: 0;
		opacity: 0; }
	70% {
		opacity: 1; }
	100% {
		width: 40px;
		height: 40px;
		opacity: 0; } }

.fancybox-slide--iframe .fancybox-content {
	max-width: 900px; }
