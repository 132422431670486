.icon-b-analysis {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-b-creative {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-b-customer {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-b-pie-chart {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-b-support {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-b-target {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-b-team {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-b-yoga {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-facebook {
  width: 0.53em;
  height: 1em;
  fill: none;
}
.icon-logo-sg {
  width: 2.23em;
  height: 1em;
  fill: initial;
}
.icon-logo {
  width: 1.25em;
  height: 1em;
  fill: initial;
}
.icon-messenger {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-play-arrow {
  width: 1em;
  height: 1em;
  fill: #FFFFFF;
}
.icon-play {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-telegram {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-viber {
  width: 1em;
  height: 1em;
  fill: initial;
}
