.block-wrapper {
	padding: 10px;
	margin-bottom: 1em;
	&-bd {
		border: 1px solid #F3F3F3;
		border-radius: 13px; } }

.step-number {
	font-size: 64px;
	font-weight: 600;
	color: #d3d3d3; }

.blue-wrapper {
	background: $accent;
	color: #fff;
	padding: 24px 20px;
	border-radius: 13px; }

.text-center {
	text-align: center; }
.text-gray {
	color: #ABABAB; }

.list li {
	position: relative;
	padding-left: 1.75em;
	margin-bottom: 0.5em;
	span {
		position: absolute;
		left: 0; } }

.block-sticky {
	position: sticky;
	top: 0; }

.timer-wrapper {
	font-weight: 600; }

.bdb {
	border-bottom: 1px solid #F3F3F3; }
